ul.location-list {
    list-style-type: none;
    margin-right: 1em;
}

ul.location-list li {
    border: rgb(32, 56, 84) 1px solid;
    border-bottom: none;
    display: block;
    padding-block-start: 15px;
    padding-block-end: 15px;
    text-align: center;
}

ul.location-list li:hover {
    background-color: #E5E2DE;
}

ul.location-list li:last-of-type {
    border-bottom: rgb(32, 56, 84) 1px solid;
}