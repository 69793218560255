#reset-view-button {
    font-weight: bold;
    font-family: monospace;
    font-size: 1.2rem;

    padding: 1rem;

    background-color: var(--header-color);
    color: var(--header-font-color);
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 7px;
    width: 100%;

    border: none;
}

#reset-view-button:hover {
    color: var(--header-font-color-hover);
}