:root {
  font-size: 16px;
  /* --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
   */

  /* Blue Version */
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: rgb(32, 56, 84);
  --bg-secondary: rgb(69, 115, 148);

  /* Red Version */
  --bg-primary: #A0423D;
  --bg-secondary: #B65854;
  --text-primary: #ececec;
  --text-secondary: #ececec;
  --transition-speed: 600ms;

  --light-blue: rgb(69, 115, 148);
  --dark-blue: rgb(32, 56, 84);
}

body {
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #A0423D;
}

main {
  margin-left: 5rem;
  display: flex;
}