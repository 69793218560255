.trip-planner {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    &__locations {
        height: 100vh;
        width: 20%;
        padding-inline-start: 1rem;
        padding-inline-end: 1rem;
        overflow-y: auto;
    }

    &__locations::-webkit-scrollbar {
        width: 0.25rem;
    }


    &__locations::-webkit-scrollbar-track {
        // background: #1e1e24;
        background: var(--dark-blue);
    }

    &__locations::-webkit-scrollbar-thumb {
        // background: #A0423D;
        background: var(--light-blue);
    }
}

.google-map {
    height: 100vh;
    width: 80%;
}

@media only screen and (max-width: 600px) {
    .trip-planner {
        flex-direction: column-reverse;

        &__locations {
            width: 100%;
            padding: 0;
        }
    }

    .google-map {
        width: 100%;
        height: 30vh;
    }
}
