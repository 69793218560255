.navbar {
    position: fixed;
    background-color: var(--bg-primary);
    transition: width 600ms ease;
    flex-wrap: nowrap;
    z-index: 10;

    &__nav {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    &__item {
        width: 100%;
    }

    &__item:last-child {
        margin-top: auto;
    }

    &__link {
        display: flex;
        align-items: center;
        height: 5rem;
        color: var(--text-primary);
        text-decoration: none;
        // filter: grayscale(100%) opacity(0.7);
        transition: var(--transition-speed);
    }

    &__link:hover {
        // filter: grayscale(0%) opacity(1);
        background: var(--bg-secondary);
        color: var(--text-secondary);
    }

    &__link svg {
        width: 2rem;
        min-width: 2rem;
        margin: 0 1.5rem;
    }

    &__link-text {
        display: none;
        margin-left: 1rem;
    }

    &__icon svg {
        font-size: 100px;
        color: red;
    }
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;

    &__text {
        display: inline;
        position: absolute;
        left: -999px;
        transition: var(--transition-speed);
    }
}

.logo svg {
    transform: rotate(0deg);
    transition: var(--transition-speed);
}

.navbar:hover .logo svg {
    transform: rotate(-180deg);
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .navbar {
        bottom: 0;
        height: 5rem;
        width: 100vw;
        display: flex;

        &__nav {
            flex-direction: row;
        }

        &__item {
            flex: 1;
        }
        
        &__item:last-child {
            margin-right: auto;
        }

        &__link {
            justify-content: center;
        }
    }

    .logo {
        display: none;
    }

    .navbar-nav {
        flex-direction: row;
    }

    .nav-link {
        justify-content: center;
    }

    main {
        margin: 0;
    }
}

/* Large screens */
@media only screen and (min-width: 600px) {
    .navbar {
        top: 0;
        width: 5rem;
        height: 100vh;
    }

    .navbar:hover {
        width: 16rem;
    }

    .navbar:hover .navbar__link-text {
        display: inline;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 600ms;
    }

    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .navbar:hover .logo svg {
        margin-left: 11rem;
    }

    .navbar:hover .logo-text {
        left: 0px;
    }
}

.dark {
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
}

.light {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #ffffff;
    --bg-secondary: #e4e4e4;
}

.solar {
    --text-primary: #576e75;
    --text-secondary: #35535c;
    --bg-primary: #fdf6e3;
    --bg-secondary: #f5e5b8;
}

.theme-icon {
    display: none;
}

.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}