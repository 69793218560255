.upload {
    width: 100%;

    h1 {
        text-align: center;
    }
}

.filepond--wrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}