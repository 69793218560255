.photos {
    width: 100%;

    h1 {
        text-align: center;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width: 600px) {
    .photos {
        &__wrapper {
            flex-direction: column;
        }
    }
}